import React, { useEffect, useState } from 'react';
import tsLogo from '../assets/logos/ts.png';
import jsLogo from '../assets/logos/js.png';
import htmlLogo from '../assets/logos/html.png';
import cssLogo from '../assets/logos/css.png';
import pythonLogo from '../assets/logos/python.png';
import sqlLogo from '../assets/logos/sql.png';
import csLogo from '../assets/logos/cs.png';
import reactLogo from '../assets/logos/react.png';
import reduxLogo from '../assets/logos/redux.png';
import vueLogo from '../assets/logos/vue.png';
import nodeLogo from '../assets/logos/node.png';
import postgresqlLogo from '../assets/logos/postgresql.png';
import snowflakeLogo from '../assets/logos/snowflake.png';
import tailwindLogo from '../assets/logos/tailwind.png';
import muiLogo from '../assets/logos/mui.svg';
import gitLogo from '../assets/logos/git.png';
import awsLogo from '../assets/logos/aws.png';
import axiosLogo from '../assets/logos/axios.png';
import jestLogo from '../assets/logos/jest.png';
import adobeLogo from '../assets/logos/adobe.png';
import herokuLogo from '../assets/logos/heroku.png';
import netlifyLogo from '../assets/logos/netlify.svg';
import jiraLogo from '../assets/logos/jira.png';

const SkillsView = () => {
  type SkillSliderState = {
    id: string,
    inView: boolean
  };
  const [skillSliderState, setSkillSliderState] = useState<SkillSliderState[]>([
    { id: 'langs', inView: true },
    { id: 'tech', inView: false },
    { id: 'other', inView: false }
  ]);

  const skillSliderChanged = (i: string) => {
    const temp = [...skillSliderState];
    temp.forEach((x) => {
      if (x.id === i) {
        x.inView = true;
      } else {
        x.inView = false;
      }
    });

    setSkillSliderState([...temp]);
  };

  const langs = [
    { name: 'TypeScript', logo: tsLogo },
    { name: 'JavaScript', logo: jsLogo },
    { name: 'HTML', logo: htmlLogo },
    { name: 'CSS', logo: cssLogo },
    { name: 'Python', logo: pythonLogo },
    { name: 'SQL', logo: sqlLogo },
    { name: 'C#', logo: csLogo }
  ];
  const tech = [
    { name: 'React', logo: reactLogo },
    { name: 'Redux', logo: reduxLogo },
    { name: 'Vue.js', logo: vueLogo },
    { name: 'Tailwind', logo: tailwindLogo },
    { name: 'MUI', logo: muiLogo },
    { name: 'Node.js', logo: nodeLogo },
    { name: 'PostgreSQL', logo: postgresqlLogo },
    { name: 'Snowflake', logo: snowflakeLogo },
  ];

  const other = [
    { name: 'Git', logo: gitLogo },
    { name: 'AWS', logo: awsLogo },
    { name: 'Axios', logo: axiosLogo },
    { name: 'Jest', logo: jestLogo },
    { name: 'Adobe', logo: adobeLogo },
    { name: 'Heroku', logo: herokuLogo },
    { name: 'Netlify', logo: netlifyLogo },
    { name: 'Jira', logo: jiraLogo },
  ];

  useEffect(() => {
    const handleScroll = () => {

      const elements = [
        { id: 'langs', e: document.querySelector('#langs')?.getBoundingClientRect() },
        { id: 'tech', e: document.querySelector('#tech')?.getBoundingClientRect() },
        { id: 'other', e: document.querySelector('#other')?.getBoundingClientRect() }
      ];

      if (elements) {
        elements.forEach(x => {
          if (x.e) {
            if (x.e.right < (window.innerWidth) && x.e.left >= 0) {
              skillSliderChanged(x.id);
            }
          }
        });
      }
    };
    document.getElementById('carousel')?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('carousel')?.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div className='h-screen bg-primary w-full flex flex-col justify-center items-center'>
      <div id='carousel' className='w-[90%] overflow-x-scroll snap-mandatory scroll-smooth snap-x p-2 max-w-[350px] md:max-w-none'>
        <div className='md:w-full w-[330%] flex justify-evenly'>
          <div id='langs' className='bg-gray-50 w-[calc(calc(100%-10%)/3)] aspect-[2/3] rounded-xl snap-center flex flex-col justify-start items-center shadow-md shadow-gray-400'>
            <h1 className='font-bold text-secondary text-2xl p-2 mt-2'>LANGUAGES</h1>
            <div className='text-center w-full h-[80%] my-auto overflow-y-scroll'>
              {langs.map((x, index) => (
                <div key={index} className='w-auto h-[27%] p-[6%] inline-block text-center'>
                  <div className='w-full h-full flex flex-col justify-center items-center'>
                    <img className='h-full w-auto' src={x.logo} alt="" />
                    <p className='text-[.9rem] font-semibold text-secondary mt-1'>{x.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id='tech' className='bg-gray-50 w-[calc(calc(100%-10%)/3)] aspect-[2/3] rounded-xl snap-center flex flex-col justify-start items-center shadow-md shadow-gray-400'>
            <h1 className='font-bold text-secondary text-2xl p-2 mt-2'>TECHNOLOGIES</h1>
            <div className='text-center w-full h-[80%] my-auto overflow-y-scroll'>
              {tech.map((x, index) => (
                <div key={index} className='w-auto h-[25%] p-[6%] inline-block text-center'>
                  <div className='w-full h-full flex flex-col justify-center items-center'>
                    <img className='h-full w-auto' src={x.logo} alt="" />
                    <p className='text-[.9rem] font-semibold text-secondary mt-1'>{x.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id='other' className='bg-gray-50 w-[calc(calc(100%-10%)/3)] aspect-[2/3] rounded-xl snap-center flex flex-col justify-start items-center shadow-md shadow-gray-400'>
            <h1 className='font-bold text-secondary text-2xl p-2 mt-2'>OTHER</h1>
            <div className='text-center w-full h-[80%] my-auto overflow-y-scroll'>
              {other.map((x, index) => (
                <div key={index} className='w-auto h-[25%] p-[5%] inline-block text-center'>
                  <div className='w-full h-full flex flex-col justify-center items-center'>
                    <img className='h-full w-auto' src={x.logo} alt="" />
                    <p className='text-[.9rem] font-semibold text-secondary mt-1'>{x.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='md:hidden flex w-40 h-6 justify-center items-center'>
        {skillSliderState.map((x, index) => (
          <div key={index} className={x.inView ? 'w-4 h-4 m-2 rounded-full bg-secondary ease-in-out duration-100' : 'w-4 h-4 m-2 rounded-full bg-gray-400 ease-in-out duration-200'} />
        ))}
      </div>
    </div>
  );
};

export default SkillsView;
