import React, { useEffect, useState } from 'react';
import {
  AiOutlineMenu,
  AiOutlineClose
} from 'react-icons/ai';

const NavBar = () => {
  type NavItems = {
    id: string,
    title: string,
    selected: boolean
  }
  const [nav, setNav] = useState<boolean>(false);
  const [navItems, setNavItems] = useState<Array<NavItems>>([
    { id: 'home', title: 'Home', selected: true },
    { id: 'about', title: 'About', selected: false },
    { id: 'skills', title: 'Skills', selected: false },
    { id: 'projects', title: 'Projects', selected: false },
    { id: 'contact', title: 'Contact', selected: false }
  ]);

  const handleNav = () => {
    setNav(!nav);
  };

  const navItemSelected = (i: string) => {
    setNav(false);
    const temp = [...navItems];
    temp.forEach((x) => {
      if (x.id === i) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });

    setNavItems([...temp]);
    window.location.replace(`/#${i}`);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    const handleScroll = () => {

      const elements = [
        { id: 'home', e: document.querySelector('#home')?.getBoundingClientRect() },
        { id: 'about', e: document.querySelector('#about')?.getBoundingClientRect() },
        { id: 'skills', e: document.querySelector('#skills')?.getBoundingClientRect() },
        { id: 'projects', e: document.querySelector('#projects')?.getBoundingClientRect() },
        { id: 'contact', e: document.querySelector('#contact')?.getBoundingClientRect() },
      ];

      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        if (elements) {
          elements.forEach(x => {
            if (x.e) {
              if (x.e.top < window.innerHeight && x.e.bottom >= 0) {
                navItemSelected(x.id);
              }
            }
          });
        }
      }, 150);
    };
    document.getElementById('app')?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('app')?.removeEventListener('scroll', handleScroll);
    };
  });



  return (
    <nav className='z-10 h-12 w-full fixed px-8 flex items-center justify-center md:justify-between bg-primary'>
      <h1 onClick={() => navItemSelected('home')} className='text-2xl font-bold text-secondary'>Jacob Penland</h1>
      <ul className='hidden md:flex'>
        {navItems.map((x, index) => (
          <div key={index} className='pl-4 flex flex-col items-center'>
            <li onClick={() => navItemSelected(x.id)} className='font-bold text-secondary cursor-pointer hover:text-accent'>{x.title}</li>
            <div className={x.selected ? 'rounded-full h-[3px] w-full bg-secondary ease-in-out duration-200' : 'rounded-full h-[3px] w-0 bg-secondary ease-in-out duration-200'} />
          </div>
        ))}
      </ul>
      <AiOutlineMenu
        className='text-secondary cursor-pointer absolute right-0 mr-8 md:hidden' size={24} onClick={() => handleNav()} />
      <div className={
        nav ?
          'md:hidden fixed h-full left-0 top-0 w-full border-r border-r-secondary ease-in-out duration-500 bg-white' :
          'md:hidden fixed h-full top-0 w-full border-r border-r-secondary left-[-100%] ease-in-out duration-500 bg-white'
      }>
        <div className='h-12 px-8 w-full flex justify-end items-center'>
          <AiOutlineClose className='text-secondary cursor-pointer md:hidden' size={24} onClick={() => handleNav()} />
        </div>
        <ul className='flex flex-col items-center pt-12 uppercase'>
          {navItems.map((x, index) => (
            <li key={index} onClick={() => navItemSelected(x.id)} className='font-bold p-4 text-secondary cursor-pointer'>{x.title}</li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
