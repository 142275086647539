import React from 'react';
import portrait from '../assets/portrait.png';
import {
  AiFillLinkedin,
  AiFillGithub
} from 'react-icons/ai';

const HomeView = () => {

  return (
    <div className='h-screen bg-primary flex flex-col justify-center items-center'>
      <div className='flex flex-col-reverse justify-center md:flex-row h-full w-4/5 md:max-w-[1000px]'>
        <div className='w-full h-1/2 md:w-3/5 md:h-full px-8 flex flex-col justify-start md:justify-center md:items-start items-center'>
          <h1 className='font-bold text-6xl text-secondary py-4 md:text-start text-center'>
            Software Engineer
          </h1>
          <p className='font-regular text-lg text-secondary text-center md:text-left'>
            {'Hi, I\'m Jacob Penland, a very self-driven and passionate Software Engineer based out of Vancouver, WA.'}
          </p>
          <ul className='flex py-4 justify-between w-28'>
            <li>
              <a href="https://www.linkedin.com/in/jacobpenland" target='__blank'>
                <AiFillLinkedin className='text-secondary' size={50} />
              </a>
            </li>
            <li>
              <a href="https://www.github.com/jacobpenland" target='__blank'>
                <AiFillGithub className='text-secondary' size={50} />
              </a>
            </li>
          </ul>
        </div>
        <div className='w-full h-1/3 md:w-2/5 md:h-full flex justify-center md:justify-center md:items-center'>
          <img
            className='rounded-full md:p-8 aspect-square object-cover md:scale-100 scale-[80%]'
            src={portrait} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeView;
