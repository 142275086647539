import React from 'react';
import NavBar from './components/NavBar';
import HomeView from './views/HomeView';
import SkillsView from './views/SkillsView';

const App = () => {

  return (
    <main id='app' className='bg-primary mx-auto snap-y h-screen scroll-smooth overflow-scroll snap-mandatory'>
      <section>
        <NavBar />
      </section>
      <section id='home' className='snap-start'>
        <HomeView />
      </section>
      <section id='about' className='snap-center h-screen bg-primary flex justify-center items-center'>
        <h1 className='text-5xl'>About</h1>
      </section>
      <section id='skills' className='snap-center h-screen bg-primary flex justify-center items-center'>
        <SkillsView />
      </section>
      <section id='projects' className='snap-center h-screen bg-red-200 flex justify-center items-center'>
        <h1 className='text-5xl'>Projects</h1>
      </section>
      <section id='contact' className='snap-end h-60 bg-gray-950 flex justify-center items-center'>
        <h1 className='text-5xl text-primary'>Contact</h1>
      </section>
    </main>
  );
};

export default App;
